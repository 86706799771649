<template>
  <div>
    <div
      v-if="Object.keys(promotionpage).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <UserToolbar
        v-if="account && Object.keys(account).length !== 0"
        :account="account"
      />
      <b-form @submit.prevent>
        <validation-observer ref="validationPromotionPageRules">
          <b-tabs
            pills
          >
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Settings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>

                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Title"
                      label-for="i-title"
                    >
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LayoutIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-title"
                          v-model="promotionpage.title"
                          placeholder="Title"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="URL"
                      label-for="i-url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="url"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-url"
                            v-model="promotionpage.url"
                            placeholder="Please set page URL and hit `Check` button to get title and image of page"
                            :state="errors.length > 0 ? false:null"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              @click="checkUrl(promotionpage.url)"
                            >
                              Check
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="promotionpage.image_id !== ''"
                    md="6"
                  >
                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-img
                          ref="previewEl"
                          rounded
                          :src="promotionpage.image_id"
                          height="80"
                        />
                      </b-media-aside>
                    </b-media>
                    <!--/ media -->
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('sites.status')"
                      label-for="i-status"
                    >
                      <b-form-select
                        v-model="status"
                        :options="statusesTranslate"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${site.user_id}/sites/${site._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="disabledSave"
                      variant="primary"
                      @click="promotionPageCreate"
                    >
                      <span class="align-middle">{{ $t('form.create') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="ShieldIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Administrator') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('sites.adminsettings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group class="mt-1">
                      <b-form-checkbox
                        v-model="is_moderate"
                        value="1"
                      >
                        {{ $t('sites.moderated') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: `/users/${site.user_id}/sites/${site._id}` }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="disabledSave"
                      variant="primary"
                      @click="promotionPageCreate"
                    >
                      <span class="align-middle">{{ $t('form.create') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import {
  BRow,
  BCol,
  BSpinner,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BFormCheckbox,
  VBTooltip,
  BMedia,
  BMediaAside,
  BImg,
} from 'bootstrap-vue'
import {
  required,
  integer,
  min,
} from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    Breadcrumbs,
    BRow,
    BCol,
    BSpinner,
    BTabs,
    BTab,
    BCard,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BImg,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pageTitle: '',
      min,
      required,
      integer,
      checking: '',
      mediaFile: [],
      promotionpage: {
        user_id: this.$route.params.userId,
        site_id: this.$route.params.siteId,
        promotion_id: this.$route.params.promotionId,
        title: '',
        path: null,
        image: '',
        status: null,
        is_moderate: false,
        date: null,
        domain: '',
        description: '',
        category: '',
        language: '',
        url: '',
        image_id: '',
      },
      status: 'active',
      promotion: [],
      site: [],
      account: {},
      authUserData: getUserData(),
      is_moderate: [],
      breadcrumbItems: [],
      disabledSave: true,
      ...options,
    }
  },
  computed: {
    statusesTranslate() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$t(`selects.statuses.${s.value}`)
        return item
      })
    },
  },
  created() {
    this.getUser(this.$route.params.userId)
  },
  methods: {
    async getPromotionData() {
      const responseData = await useJwt.getPromotion(this.$route.params.promotionId)
      this.promotion = responseData.data.promotion || []
      this.setBreadcrumbs()
    },
    async getSiteData(id) {
      const responseData = await useJwt.getSite(id)
      this.site = responseData.data.site || []
      this.pageTitle = this.site.title
      this.promotionpage.domain = this.site.domain
      this.getPromotionData()
    },
    async getUser(id) {
      const responseData = await useJwt.getUser(id)
      this.account = responseData.data.account || {}
      this.getSiteData(this.$route.params.siteId)
    },
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.site.user_id}`,
        },
        {
          text: this.$t('page.menu.sites'),
          to: `/users/${this.site.user_id}/sites/`,
        },
        {
          text: this.site.title,
          to: `/users/${this.site.user_id}/sites/${this.site._id}`,
        },
        {
          text: this.$t('promotions.promotions'),
          to: `/users/${this.site.user_id}/sites/${this.site._id}/promotions`,
        },
        {
          text: this.promotion.title,
          to: `/users/${this.site.user_id}/sites/${this.site._id}/promotions/${this.$route.params.promotionId}`,
        },
        {
          text: this.$t('promotions.create'),
          active: true,
        },
      ]
    },
    promotionPageCreate() {
      this.$refs.validationPromotionPageRules.validate().then(success => {
        if (success) {
          this.promotionpage.status = this.status
          this.promotionpage.is_moderate = this.is_moderate
          useJwt.createPromotionPage(this.promotionpage).then(() => {
            this.$router.push(`/users/${this.site.user_id}/sites/${this.site._id}/promotions/${this.$route.params.promotionId}`)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Promotion page created',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    async checkUrl(url) {
      const params = {
        url,
        parser: {
          title: 'core.parser.processor.opengraph.parser.OpenGraphParser',
          fields: [],
        },
        dateParser: {
          title: 'core.parser.processor.date.parser.DefaultDateParser',
          fields: [
            {
              key: 'mask',
              value: '%d %m %y',
            },
          ],
        },
        encoding: '',
        languageParser: {
          title: 'core.parser.processor.language.parser.DefaultLanguageParser',
          fields: [],
        },
      }
      await useJwt.parserConvert(params)
        .then(response => {
          this.promotionpage.title = response.data.title
          this.promotionpage.image_id = response.data.image
          this.disabledSave = false
        })
        .catch(error => {
          this.disabledSave = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: this.$t(error.response.data.error),
            },
          })
        })
    },
  },
}
</script>
<style scoped>
  .select-append {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select-append + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>
